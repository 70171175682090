import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { TrackingService } from '@/app-tracking.service';
import { DrawerComponent } from '@/shared-ui/drawer/drawer.component';
import { SpaceboySaysComponent } from '@/shared-ui/spaceboy-says/spaceboy-says.component';
import { SpinnerBoxComponent } from '@/shared-ui/spinner-box.component';

import { AclDirective } from '../acl.directives';
import { AcknowledgementService } from './acknowledgement.service';
import { NewsAckResourceType, UserNews } from './news';
import { NewsComponent } from './news.component';
import { NewsService } from './news.service';

@Component({
  selector: 'turbine-news-pane',
  templateUrl: './news-pane.component.html',
  styleUrls: ['./news-pane.component.scss'],
  standalone: true,
  imports: [
    DrawerComponent,
    RouterLink,
    AclDirective,
    SpinnerBoxComponent,
    SpaceboySaysComponent,
    NewsComponent,
  ],
})
export class NewsPaneComponent implements OnInit {
  @Input() me;
  @Input() isNewsPaneCollapsed = true;
  @Output() isCollapsed = new EventEmitter<boolean>();
  @Output() hasUnreadNewsChange: EventEmitter<boolean> = new EventEmitter();

  news: UserNews[];
  has_unread_news: boolean;
  news_refresh_seconds = 300; // auto refresh every 30 seconds
  private news_reloader = new Subject<any>();

  constructor(
    private acknowledgementService: AcknowledgementService,
    private newsService: NewsService,
    public trackingService: TrackingService,
  ) {}

  ngOnInit() {
    timer(0, 1000 * this.news_refresh_seconds)
      .pipe(
        switchMap(() => this.newsService.findForUser()),
        takeUntil(this.news_reloader),
      )
      .subscribe((news) => {
        this.news = news;
        this.refreshHasUnreadNews();
      });
  }

  reloadNews() {
    this.newsService.findForUser().subscribe((news) => {
      this.news = news;
      this.refreshHasUnreadNews();
    });
  }

  closeNewsPane() {
    this.isCollapsed.emit(true);
  }

  refreshHasUnreadNews() {
    this.has_unread_news = this.news.find((n) => !n.acked) != null;
    this.hasUnreadNewsChange.emit(this.has_unread_news);
  }

  markAllNewsAsRead() {
    // get all unread news
    const news_id_arr = this.news
      .filter((news) => !news.acked)
      .map((news) => news.id);
    // send
    this.acknowledgementService
      .markAllAsRead(this.me.id, NewsAckResourceType, news_id_arr)
      .subscribe(() => this.reloadNews());
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'News',
      action: 'click',
      label: 'News: mark all as read',
      value: '',
    });
  }

  markNewsAsRead(news: UserNews) {
    if (news.acked) {
      return;
    }
    this.acknowledgementService
      .markAsRead(this.me.id, NewsAckResourceType, news.id)
      .subscribe(() => this.reloadNews());
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'News',
      action: 'click',
      label: 'News: mark as read',
      value: news.id,
    });
  }

  markNewsAsUnread(news: UserNews) {
    this.acknowledgementService
      .markAsUnread(this.me.id, NewsAckResourceType, news.id)
      .subscribe(() => this.reloadNews());
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'News',
      action: 'click',
      label: 'News: mark as unread',
      value: news.id,
    });
  }
}
