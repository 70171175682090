import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { TrackingService } from '@/app-tracking.service';
import { SpinnerBoxComponent } from '@/shared-ui/spinner-box.component';

@Component({
  selector: 'turbine-environment-idling-public-status',
  templateUrl: './environment-idling-public-status.component.html',
  styleUrls: ['./environment-idling-public-status.component.scss'],
  standalone: true,
  imports: [SpinnerBoxComponent, RouterLink],
})
export class EnvironmentIdlingPublicStatusComponent {
  platform_name: string;
  environment_name: string;
  originUrl: string;

  constructor(
    private routes: ActivatedRoute,
    public trackingService: TrackingService,
  ) {
    this.routes.params.subscribe((params) => {
      this.platform_name = params['platform_name'];
      this.environment_name = params['environment_name'];
    });

    this.routes.queryParams.subscribe((params) => {
      this.originUrl = params['origin_url'];
    });
  }
}
