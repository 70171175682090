import { Component, Input, OnInit } from '@angular/core';
import { of, Subject, timer } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

import type { News } from '@/users/news/news';
import { NewsService } from '@/users/news/news.service';

@Component({
  standalone: true,
  selector: 'turbine-announce',
  styleUrl: './announce.component.scss',
  template: `
    @if (news) {
      <div
        class="announce py-2 px-3 text-white position-relative"
        [class.announce--incident]="news.incident"
      >
        <div class="d-flex justify-content-start align-items-center">
          <strong class="mr-2">{{ news.title }}</strong>
          <span class="flex-grow-1 font-italic">{{ news.description }}</span>
          @for (attachment of news.attachments; track $index) {
            @if (attachment.type === 'URL') {
              <a
                class="btn btn-sm btn-primary mx-1"
                [href]="attachment.url"
                target="_blank"
              >
                {{ attachment.text }}
              </a>
            }
          }
        </div>
      </div>
    }
  `,
})
export class AnnounceComponent implements OnInit {
  @Input({ required: true }) refreshSeconds: number;
  private reload = new Subject<void>();
  news: News;

  constructor(private newsService: NewsService) {}

  ngOnInit(): void {
    timer(0, 1000 * this.refreshSeconds)
      .pipe(
        switchMap(() =>
          this.newsService.getHeadLine().pipe(catchError(() => of(null))),
        ),
        takeUntil(this.reload),
      )
      .subscribe({
        next: (news) => (this.news = news),
        error: () => (this.news = null),
      });
  }
}
