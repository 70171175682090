import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { TrackingService } from '@/app-tracking.service';

@Component({
  selector: 'turbine-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  standalone: true,
})
export class DrawerComponent implements OnChanges, OnDestroy {
  @ViewChild('drawer') drawerElementRef: ElementRef<HTMLDivElement>;
  @Input() me;
  @Input() col = 'col-4';
  @Input() name = 'default';
  @Input() title = '';
  @Input() titleIcon = '';
  @Input() isDrawerCollapsed = true;
  @Output() isCollapsed = new EventEmitter<boolean>();
  timer;

  constructor(public trackingService: TrackingService) {}

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent() {
    if (!this.isDrawerCollapsed) {
      this.closeDrawer();
    }
  }

  ngOnChanges() {
    // double-scroll prevention
    if (!this.isDrawerCollapsed) {
      document.body.classList.add(`drawer-open--${this.name}`);
      this.timer = setTimeout(() => {
        this.fixPosition();
        clearTimeout(this.timer);
      }, 10);
    } else {
      document.body.classList.remove(`drawer-open--${this.name}`);
    }
  }

  ngOnDestroy() {
    document.body.classList.remove(`drawer-open--${this.name}`);
  }

  fixPosition(): void {
    const DrawerEl = document.querySelectorAll('.drawer');
    const wrapperEl: HTMLElement = document.querySelector('.wrapper');
    const scrollTop =
      window.scrollY !== undefined ? window.scrollY : window.pageYOffset;

    DrawerEl.forEach((el: HTMLElement) => {
      if (scrollTop <= Math.round(wrapperEl.offsetTop)) {
        el.style.top = `${Math.round(wrapperEl.offsetTop - scrollTop)}px`;
      } else {
        el.style.top = `0px`;
      }
    });
  }

  closeDrawer() {
    this.isCollapsed.emit(true);
  }
}
