import { CommonModule } from '@angular/common';
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalButtonStyle, ModalContent } from './modal';

@Component({
  standalone: true,
  selector: 'turbine-modal',
  templateUrl: './modal.component.html',
  imports: [CommonModule],
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalContent', { read: ViewContainerRef, static: true })
  modalContent: ViewContainerRef;
  templateRef: TemplateRef<HTMLElement>;
  private componentRef: ComponentRef<any>;
  /* onDone is emitted when the modal's job is done */
  @Output() onDone = new EventEmitter();
  @Output() onCancelOrClose = new EventEmitter();

  title: string;
  /* A component to display inside the ModalComponent */
  child: any = null;
  /* Inputs of the child component */
  childInputs: any;
  /* Modal's confirmation */
  mainActionBtnName: string = null;
  mainActionBtnIsLoadingName = 'Loading...';
  mainActionBtnStyle: ModalButtonStyle = 'primary';
  confirmWithEnter = false;
  cancelBtnName = 'Cancel';
  /* If true, the mainAction is called on click on the close button.
   * Else, we hide the modal (default) */
  mainActionOnClose = false;
  /**
   * Simple text to show in the modal-body, for more complex content, use a child component
   * by setting the modal's initialState.child */
  content: string = null;
  /* callback that can be attached to the modal's initialState,
   * will be executted after onDone */
  onModalClosed: any = null;
  isLoading: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.confirmWithEnter && event.code === 'Enter') {
      this.modalChildAction();
    }
  }

  ngOnInit() {
    if (!this.child) return;

    const factory: ComponentFactory<ModalContent> =
      this.componentFactoryResolver.resolveComponentFactory<ModalContent>(
        this.child,
      );

    if (!factory) return;

    this.modalContent.clear();

    this.componentRef = this.modalContent.createComponent(factory);
    for (const property in this.childInputs) {
      if (this.childInputs[property]) {
        this.componentRef.instance[property] = this.childInputs[property];
      }
    }

    this.componentRef.instance.onDone = this.onDone;

    if (this.componentRef.instance.isLoading) {
      this.componentRef.instance.isLoading.subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
      });
    }

    if (this.componentRef.instance.onComponentUpdated) {
      this.componentRef.instance.onComponentUpdated.subscribe((e) => {
        this.onDone.emit(e);
      });
    }
  }

  ngOnDestroy() {
    this.content = null;
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.modalContent) {
      this.modalContent.clear();
    }
  }

  async onComponentUpdated(e: any) {
    if (this.componentRef?.instance?.onComponentUpdated) {
      await this.componentRef.instance.onComponentUpdated(e);
    }
  }

  async modalChildAction() {
    if (!this.child) {
      this.onDone.emit();
    }
    if (this.componentRef?.instance?.modalChildAction) {
      await this.componentRef.instance.modalChildAction();
    }
    if (this.onModalClosed) {
      this.onModalClosed();
    }
  }

  cancelOrCloseModal(): void {
    if (this.mainActionOnClose) {
      this.modalChildAction();
    }
    this.onCancelOrClose.emit();
    this.bsModalRef.hide();
  }
}
