import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter } from 'rxjs/operators';

import { TrackingService } from '@/app-tracking.service';
import { ConfigurationService } from '@/core/configuration.service';
import { AnnounceComponent } from '@/core/dialog/announce.component';
import { DialogComponent } from '@/core/dialog/dialog.component';
import { HeaderComponent } from '@/core/header/header.component';
import { KonamiComponent } from '@/core/konami.component';
import { initUserMe } from '@/initUserMe';
import { SidebarComponent } from '@/sidebar/sidebar.component';
import { TeamService } from '@/teams/teams.service';
import { UserFeedbackComponent } from '@/user-feedback/user-feedback.component';
import { NewsPaneComponent } from '@/users/news/news-pane.component';
import { UserDetails } from '@/users/user';
import { UserService } from '@/users/user.service';
import { WhatsNewComponent } from '@/users/whatsnew/whatsnew.component';

@Component({
  selector: 'turbine-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  standalone: true,
  imports: [
    DialogComponent,
    HeaderComponent,
    NewsPaneComponent,
    WhatsNewComponent,
    SidebarComponent,
    RouterOutlet,
    UserFeedbackComponent,
    AnnounceComponent,
    KonamiComponent,
  ],
})
export class AppLayoutComponent implements OnInit {
  public isSidebarCollapsed = false;
  public isNewsPaneCollapsed = true;
  me: UserDetails;
  hasUnreadNews: boolean;
  gaPageType = '';
  isPageLoading = true;

  constructor(
    private userService: UserService,
    private teamService: TeamService,
    private configurationService: ConfigurationService,
    public trackingService: TrackingService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.isSidebarCollapsed =
      this.configurationService.getCookie('turbine.ui.sidebar.collapsed') ===
      'true';

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let pageType = this.route.snapshot.firstChild.data.page_type;
        if (!pageType && this.route.snapshot.firstChild.firstChild) {
          pageType = this.route.snapshot.firstChild.firstChild.data.page_type;
        }
        const [level1, level2, level3, level4] = this.router.url
          .split('?')[0]
          .split('/')
          .filter((a) => a);
        const dataObject = {
          event: 'gtmReady',
          type: pageType || '',
          level1: level1 || '',
          level2: level2 || '',
          level3: level3 || '',
          level4: level4 || '',
        };

        this.trackingService.gtmSend(dataObject);
        this.gaPageType = pageType;
      });
  }

  ngOnInit(): void {
    if (!this.userService.me) {
      // If not setted by APP_FACTORY, after login for ex
      initUserMe(this.userService, null, this.teamService).subscribe(
        (teams) => {
          this.userService.me.teams = teams;
          this.setUserMe();
        },
      );
    } else {
      this.setUserMe();
    }
  }

  setUserMe(): void {
    this.me = this.userService.me;
    this.isPageLoading = false;
  }

  toggleSidebar(): void {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    this.configurationService.setCookie(
      'turbine.ui.sidebar.collapsed',
      this.isSidebarCollapsed.toString(),
      999999,
    );
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'App',
      action: 'click',
      label: 'Sidebar :: Show',
      value: !this.isSidebarCollapsed,
    });
  }

  toggleNewsPane(): void {
    this.isNewsPaneCollapsed = !this.isNewsPaneCollapsed;
  }
}
