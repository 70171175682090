/* Copyright Coorganix SAS */

import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { Alert } from './dialog';

@Injectable({ providedIn: 'root' })
export class DialogService {
  private _alertBus: EventEmitter<Alert> = new EventEmitter<Alert>();

  alert(alert: Alert): void {
    this._alertBus.emit(alert);
  }

  listenAlert(listenAlert: (alert: Alert) => void): Subscription {
    return this._alertBus.subscribe((alert) => listenAlert(alert));
  }
}
