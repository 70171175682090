<turbine-announce [refreshSeconds]="300" />
<turbine-dialog />
<turbine-header (newsPaneChange)="toggleNewsPane()" [newsPaneCollapsed]="isNewsPaneCollapsed" [hasUnreadNews]="hasUnreadNews" />

@if (!isPageLoading && me) {
  <main class="wrapper" role="main">
    @if (!isNewsPaneCollapsed) {
      <turbine-news-pane
        [me]="me"
        [isNewsPaneCollapsed]="isNewsPaneCollapsed"
        (isCollapsed)="toggleNewsPane()"
        (hasUnreadNewsChange)="hasUnreadNews = $event"
      />
    }
    <turbine-whatsnew [me]="me" [refresh_seconds]="300" />
    <button
      type="button"
      id="sidebarCollapse"
      (click)="toggleSidebar()"
      class="border-0"
      [class.active]="isSidebarCollapsed"
      aria-label="Toggle sidebar"
      [attr.aria-expanded]="!isSidebarCollapsed"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>

    <turbine-sidebar [isCollapsed]="isSidebarCollapsed" />

    <div id="content" [class.bg-custom]="gaPageType === 'root_view'" [class.collapsed-sidebar]="isSidebarCollapsed">
      <article role="article" class="w-100">
        <router-outlet />
      </article>
    </div>

    <div id="top-of-site-pixel-anchor"></div>
  </main>
}

@if (me?.name !== null) {
  <turbine-user-feedback />
}

<turbine-konami />
