<form class="mb-2" [formGroup]="form">
  <!-- Code Editor -->
  <section title="Code editor">
    <h5>Code editor</h5>
    <p>Choose how Turbine's editors looks to you, select a theme.</p>
    <div class="d-flex justify-content-center">
      <label for="theme-light" class="card m-2">
        <img src="/assets/prefs/turbine-light.svg" class="border-bottom" alt="Editor light theme" />
        <div class="card-body py-2 px-3">
          <div class="form-check form-check-inline">
            <input id="theme-light" type="radio" class="form-check-input" formControlName="editorTheme" value="light" />
            <label for="theme-light" class="form-check-label">Light theme</label>
          </div>
        </div>
      </label>
      <label for="theme-dark" class="card m-2">
        <img src="/assets/prefs/vs-dark.svg" class="border-bottom" alt="Editor dark theme" />
        <div class="card-body py-2 px-3">
          <div class="form-check form-check-inline">
            <input id="theme-dark" type="radio" class="form-check-input" formControlName="editorTheme" value="dark" />
            <label for="theme-dark" class="form-check-label">Dark theme</label>
          </div>
        </div>
      </label>
    </div>
  </section>
  <!-- Deployments -->
  <section title="Deployments">
    <h5>Deployments</h5>
    <div class="form-check">
      <input id="deployment-confirmation" class="form-check-input" type="checkbox" formControlName="deploymentConfirmation" />
      <label class="form-check-label" for="deployment-confirmation">Ask for confirmation before deploying a component</label>
    </div>
  </section>
</form>
