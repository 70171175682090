import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { setTheme } from 'ngx-bootstrap/utils';

@Component({
  standalone: true,
  selector: 'turbine-root',
  template: `<router-outlet />`,
  imports: [RouterOutlet],
})
export class AppComponent {
  constructor() {
    setTheme('bs4');
  }
}
