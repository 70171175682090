@if (news) {
  <div class="d-flex flex-row">
    <div class="mx-3 mb-3 mt-0 d-flex flex-column align-items-center justify-content-center w-100">
      @if (news.incident) {
        <i class="fa fa-exclamation-triangle fa-5x text-danger mb-2" aria-hidden="true"></i>
      }
      @if (!news.incident && !newsHasImage) {
        <img src="/assets/turbine-splash.png" alt="Turbine" width="423" height="228" />
      }
      <h4 class="my-3 w-75 front-weight-light">{{ news.title }}</h4>
      <p [innerHTML]="news.description | safeHTML"></p>
      @for (attachment of news.attachments; track attachment) {
        @if (attachment.type === 'TEXT') {
          <p class="mx-1">
            {{ attachment.text }}
          </p>
        }
        @if (attachment.type === 'IMAGE') {
          <img class="mx-1 d-block mw-100" [src]="attachment.url" [alt]="attachment.text" />
        }
      }
      @for (attachment of newsLinks; track attachment; let i = $index) {
        @if (i === 0) {
          <h5 class="d-none">Links:</h5>
        }
        <p class="mb-0">
          <a class="btn btn-sm btn-link" [href]="attachment.url" target="_blank">
            {{ attachment.text }}
          </a>
        </p>
      }
    </div>
  </div>
}
