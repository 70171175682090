import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { JSON_HEADERS, NO_CACHE_HEADERS, req_opts } from './api';
import { ConfigurationService } from './configuration.service';

export const AUTH_COOKIE_LOGGED_AS = 'logged-as';

class AuthRequest {
  provider: string;
  // OAuth requirement
  code?: string;
  redirect_uri?: string;
  // LDAP requirement
  username?: string;
  password?: string;
}

@Injectable({ providedIn: 'root' })
export class LoginService {
  isLoggedIn = true;

  bus: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private configurationService: ConfigurationService,
    private http: HttpClient,
  ) {}

  headers(...headers: { [name: string]: string }[]): HttpHeaders {
    const all_headers = headers.slice();
    all_headers.push(NO_CACHE_HEADERS);
    return req_opts(...all_headers);
  }

  resetLogin(): void {
    this.isLoggedIn = false;
    this.bus.emit(false);
  }

  // give oauth redirect url
  getRedirectUrl(): string {
    return encodeURI(
      `${window.location.protocol}//${window.location.host}/oauth/pingone/_callback`,
    );
  }

  //
  setStateUrl(redirectUrl: string) {
    window.localStorage.setItem('state-code', this.getRandomHash());
    window.localStorage.setItem('state-url', redirectUrl);
  }

  getStateCode(): string {
    return window.localStorage.getItem('state-code');
  }

  getStateUrl(stateCode: string): string {
    const expectedStateCode = window.localStorage.getItem('state-code');
    if (expectedStateCode && stateCode === expectedStateCode) {
      return window.localStorage.getItem('state-url');
    }
    return '/';
  }

  getRandomHash() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  authorize(provider: string) {
    const redirectUrl = this.getRedirectUrl();
    const state = this.getStateCode();

    this.configurationService
      .getConfiguration()
      .toPromise()
      .then((conf) => {
        document.location.href = `${conf.turbine.api.auth}/oauth/authorize?provider=${provider}&redirect_uri=${redirectUrl}&state=${state}`;
      });
  }

  authenticate(request: AuthRequest): Observable<boolean> {
    return this.configurationService.getConfiguration().pipe(
      switchMap((conf) =>
        this.http
          .post(`${conf.turbine.api.auth}/auth`, request, {
            headers: req_opts(NO_CACHE_HEADERS, JSON_HEADERS),
            withCredentials: true,
          })
          .pipe(
            map(() => {
              this.isLoggedIn = true;
              this.configurationService.removeCookie(AUTH_COOKIE_LOGGED_AS);
              return true;
            }),
            catchError((error) => {
              if (error && error.status === 401) {
                return of(false);
              } else {
                return observableThrowError(error || 'Server error');
              }
            }),
          ),
      ),
    );
  }

  logout(): Observable<boolean> {
    return this.configurationService.getConfiguration().pipe(
      switchMap((conf) =>
        this.http
          .post(`${conf.turbine.api.auth}/logout`, null, {
            headers: req_opts(NO_CACHE_HEADERS, JSON_HEADERS),
            withCredentials: true,
          })
          .pipe(
            map(() => {
              this.isLoggedIn = false;
              this.configurationService.removeCookie(AUTH_COOKIE_LOGGED_AS);
              return true;
            }),
            catchError((error) => {
              console.error('error while logout', error);
              return observableThrowError(error || 'Server error');
            }),
          ),
      ),
    );
  }
}
