import { throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { TeamService } from './teams/teams.service';
import { UserService } from './users/user.service';

export const initUserMe = (
  userService: UserService,
  resolve: (value?: boolean | PromiseLike<boolean>) => void,
  teamService: TeamService,
) =>
  userService
    .getUser('me')
    // handle user loading errors
    .pipe(
      catchError((error: any) => {
        if (resolve) {
          resolve(false);
        }
        return throwError(
          () => new Error(error || 'Server error during user "me" loading'),
        );
      }),
      mergeMap((user) => {
        // intialize current user
        userService.me = user;
        userService.me$.set(user);
        // then start current user's team initialization
        return (
          teamService
            .getTeamsByUser(userService.me.id)
            // handle user loading errors
            .pipe(
              catchError((error: any) => {
                if (resolve) {
                  resolve(false);
                }
                return throwError(
                  () =>
                    new Error(error || 'Server error during user "me" loading'),
                );
              }),
            )
        );
      }),
    );
