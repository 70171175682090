import { Injectable, signal } from '@angular/core';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import type { ResourceBinding } from '@/acl/roles/roles';
import { TurbineApiService } from '@/core/api.service';
import { ConfigurationService } from '@/core/configuration.service';
import { AUTH_COOKIE_LOGGED_AS } from '@/core/login.service';

import type {
  ACL,
  DomainRoleBinding,
  User,
  UserDetails,
  UserPreferences,
} from './user';

@Injectable({ providedIn: 'root' })
export class UserService {
  // initialized by app module APP_FACTORY
  me: UserDetails = null;
  me$ = signal<UserDetails>(null);

  constructor(
    private api: TurbineApiService,
    private configurationService: ConfigurationService,
  ) {}

  getUsersByIds(ids: string[]): Observable<User[]> {
    if (!ids || ids.length === 0) {
      return of([]);
    } else {
      const setIds = new Set(ids);
      const uniqIds = Array.from(setIds.values());

      return this.api.getJson(
        `${this.api.urls.auth}/users?${uniqIds
          .map((id) => `id=${id}`)
          .join('&')}`,
        [],
      );
    }
  }

  getUser(id: string): Observable<UserDetails> {
    return this.api.getJson(`${this.api.urls.auth}/users/${id}`, []);
  }

  updateMe(): void {
    if (!this.me) {
      return;
    }
    this.api
      .getJson(`${this.api.urls.auth}/users/${this.me.id}`, [])
      .subscribe((me) => {
        this.me = me;
        this.me$.set(me);
      });
  }

  createOrUpdatePreferences(
    preferences: UserPreferences,
  ): Observable<UserPreferences> {
    return this.api
      .putJson(
        `${this.api.urls.auth}/users/${this.me.id}/preferences`,
        { ...this.me.preferences, ...preferences },
        { etag: false, authoring: false },
      )
      .pipe(
        map((preferences: UserPreferences) => {
          this.me$.update(() => ({ ...this.me, preferences }));
          this.me.preferences = preferences;
          return preferences;
        }),
      );
  }

  authenticateAs(id: string): Observable<boolean> {
    return this.api
      .postJson(`${this.api.urls.auth}/users/${id}/auth`, null)
      .pipe(
        map(() => {
          this.configurationService.setCookie(AUTH_COOKIE_LOGGED_AS, id, 1);
          return true;
        }),
        catchError((error) => {
          if (error && error.status === 401) {
            return of(false);
          } else {
            return observableThrowError(error || 'Server error');
          }
        }),
      );
  }

  private isInvalidACL(acl: ACL): boolean {
    return !acl.resource || !acl.verbs || acl.verbs.length === 0;
  }

  private normalizeDomainName(domain: string): string {
    return domain.endsWith('/') ? domain : `${domain}/`;
  }

  private getSortedDomainRoles(): DomainRoleBinding[] {
    return this.me.domain_roles_binding.sort((a, b) =>
      a.domain.toLowerCase().localeCompare(b.domain.toLowerCase()),
    );
  }

  private isDomainMismatch(
    domain: string,
    domainName: string,
    domainRole: DomainRoleBinding,
  ): boolean {
    return (
      domain && !domain.startsWith(domainName) && domain != domainRole.domain
    );
  }

  private hasRequiredVerbs(
    acl: ACL,
    aggregatedRoles: ResourceBinding[],
  ): boolean {
    for (const resourceBinding of aggregatedRoles) {
      if (resourceBinding.resource_name === acl.resource) {
        for (const requiredVerb of acl.verbs) {
          if (resourceBinding.verbs.includes(requiredVerb)) {
            return true;
          }
        }
        // if domain was set and we are here we can break to continue in others given ACLs.
        // if domain is not set, continue looking for verbs in other domains
        if (acl.domain) break;
      }
    }
    return false;
  }

  hasOneACL(acls: ACL[]): boolean {
    if (!acls || acls.length === 0 || !this.me) return false;

    if (this.me.domain_roles_binding) {
      for (const acl of acls) {
        if (this.isInvalidACL(acl)) continue;
        for (const domainRole of this.getSortedDomainRoles()) {
          const domainName = this.normalizeDomainName(domainRole.domain);
          if (this.isDomainMismatch(acl.domain, domainName, domainRole)) {
            continue;
          }
          if (domainRole.aggregated_roles) {
            if (this.hasRequiredVerbs(acl, domainRole.aggregated_roles)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }
}
