import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { LoginService } from './login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
  constructor(
    private router: Router,
    private loginService: LoginService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    if (!this.loginService.isLoggedIn) {
      console.log(
        'You are not allowed to view this page. You are redirected to login Page',
      );

      const queryParamEntries = route.queryParamMap.keys.map((key) => [
        key,
        route.queryParamMap.get(key),
      ]);

      // Store the attempted URL for
      this.loginService.setStateUrl(state.url);

      this.router.navigate(['/login'], {
        queryParams: {
          platform: Object.fromEntries(queryParamEntries)['platform'],
          environment: Object.fromEntries(queryParamEntries)['environment'],
          idle_from: Object.fromEntries(queryParamEntries)['idle_from'],
        },
        queryParamsHandling: 'merge',
      });
      return false;
    }
    return true;
  }
}
