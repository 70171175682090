import { Injectable, RendererFactory2 } from '@angular/core';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

import { ModalComponent } from './modal.component';

@Injectable({ providedIn: 'root' })
export class ModalService extends BsModalService {
  constructor(
    private bsModalService: BsModalService,
    rendererFactory: RendererFactory2,
    clf: ComponentLoaderFactory,
    modalDefaultOption: ModalOptions,
  ) {
    super(rendererFactory, clf, modalDefaultOption);
  }

  showModal(
    initialState?: ModalOptions<ModalComponent>,
  ): BsModalRef<ModalComponent> {
    return this.bsModalService.show(ModalComponent, initialState);
  }
}
