<div class="m-spaceboy--{{ mode }} {{ customCls }}">
  @if (statusRole) {
    <img src="/assets/spaceboy-{{ statusRole }}.svg" alt="Turbine spaceboy" class="spaceboy" />
  } @else {
    <img src="/assets/spaceboy.svg" alt="Turbine spaceboy" class="spaceboy" />
  }

  <div class="instructions">
    <ng-content></ng-content>
  </div>
</div>
