import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

import { SanitizeSafeHtmlPipe } from '@/core/dialog/safe-html.pipe';
import { RelativeTimePipe } from '@/core/utils/relative-time.pipe';

import { News } from './news';

@Component({
  selector: 'turbine-news',
  templateUrl: './news.component.html',
  styleUrls: ['news.component.scss'],
  standalone: true,
  imports: [DatePipe, RelativeTimePipe, SanitizeSafeHtmlPipe],
})
export class NewsComponent {
  @Input()
  news: News;
}
