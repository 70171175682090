<div class="drawer__backdrop" [class.slidein]="!isDrawerCollapsed" (click)="closeDrawer()"></div>

<div #drawer class="{{ col }} {{ name }} drawer" [class.slidein]="!isDrawerCollapsed" role="complementary" [attr.aria-label]="'Complementary panel: ' + title">
  <div class="drawer__body">
    <div class="d-flex align-items-center justify-content-between">
      <h2 class="title"><i [class]="titleIcon" class="mr-1" aria-hidden="true"></i><span [innerHTML]="title"></span></h2>
      <a (click)="closeDrawer()" title="Close notifications">
        <i class="fas fa-times pb-2" aria-hidden="true"></i>
      </a>
    </div>

    <hr class="mt-1 mb-3" />

    <ng-content></ng-content>
  </div>
</div>
