export interface Acknowledgement {
  user_id: string;
  ack_id: string;
  ack_date: any;
}

export const HeadLineNewsId = 'head_line';

export const NewsAckResourceType = 'news';

type NewsAttachmentType = 'TEXT' | 'URL' | 'IMAGE';

export interface NewsAttachment {
  type: NewsAttachmentType;
  text?: string;
  url?: string;
}

export interface NewsData {
  title: string;
  description: string;
  published_at: Date;
  attachments: NewsAttachment[];
  head_line: boolean;
  feature_announcement: boolean;
  incident: boolean;
  // computed
  ui_status?: 'deprecated' | 'published' | 'soon' | 'later';
}

export interface News extends NewsData {
  id: string;
  created_at: Date;
  updated_at: Date;
}

export const setNewsStatus = (n: NewsData) => {
  const now = new Date().getTime();
  const day = 1000 * 60 * 60 * 24;
  const pub = n.published_at.getTime();
  if (now - pub > 180 * day) {
    n.ui_status = 'deprecated';
  } else if (now - pub > 0) {
    n.ui_status = 'published';
  } else if (now - pub > -(3 * day)) {
    n.ui_status = 'soon';
  } else {
    n.ui_status = 'later';
  }
};

export interface UserNews extends News {
  acked: boolean;
  ack?: Acknowledgement;
}

export const defaultNewsDate = (): NewsData => ({
  title: 'Turbine is getting cooler than ever!',
  description: null,
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  published_at: (function () {
    const res = new Date();
    res.setFullYear(res.getFullYear() + 1);
    return res;
  })(),
  attachments: [],
  head_line: false,
  feature_announcement: false,
  incident: false,
});
