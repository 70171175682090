import { Injectable } from '@angular/core';

interface Period {
  theme: string;
  start: { month: number; day: number };
  end: { month: number; day: number };
}

@Injectable({ providedIn: 'root' })
export class ThemeService {
  public theme: string;

  constructor() {
    this.theme = this.getThemeByDate();
  }

  private calculateEasterDate(year: number): Date {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const month = Math.floor((h + l - 7 * m + 114) / 31);
    const day = ((h + l - 7 * m + 114) % 31) + 1;

    return new Date(year, month - 1, day);
  }

  private isInRange(currentDate: Date, period: Period) {
    const start = new Date(
      currentDate.getFullYear(),
      period.start.month - 1,
      period.start.day,
    );
    const end = new Date(
      currentDate.getFullYear(),
      period.end.month - 1,
      period.end.day,
    );

    return currentDate >= start && currentDate <= end;
  }

  private getThemeByDate(): string {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const easterDate = this.calculateEasterDate(year);

    const themePeriods: Period[] = [
      {
        theme: 'HALLOWEEN',
        start: { month: 10, day: 25 },
        end: { month: 11, day: 6 },
      },
      {
        theme: 'CHRISTMAS',
        start: { month: 12, day: 1 },
        end: { month: 12, day: 31 },
      },
      {
        theme: 'NEWYEAR',
        start: { month: 1, day: 1 },
        end: { month: 1, day: 14 },
      },
      {
        theme: 'EASTER',
        start: {
          month: easterDate.getMonth() + 1,
          day: easterDate.getDate() - 7,
        },
        end: {
          month: easterDate.getMonth() + 1,
          day: easterDate.getDate() + 7,
        },
      },
    ];

    for (const period of themePeriods) {
      if (this.isInRange(currentDate, period)) {
        return period.theme;
      }
    }

    return 'HIGHLIGHT';
  }
}
