import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '@/core/login.service';

@Component({
  template: '',
  standalone: true,
})
export class LogoutComponent implements OnInit {
  constructor(
    private loginService: LoginService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loginService.logout().subscribe((loggedOut) => {
      if (loggedOut) {
        this.router.navigate(['login']).then(() => {
          document.location.reload();
        });
      }
    });
  }
}
