import { Component, effect, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import { UserService } from '@/users/user.service';

const RESSOURCES_ICONS = {
  environments: 'fas fa-boxes',
  components: 'fas fa-box',
  templates: 'fas fa-file-code',
  federations: 'icon-federation',
  platforms: 'fas fa-cloud',
  teams: 'fas fa-users',
  domains: 'fas fa-sitemap',
};

interface Ressource {
  name: string;
  icon: string;
  favorites: string[];
}

@Component({
  standalone: true,
  selector: 'turbine-favorite-dropdown',
  templateUrl: './favorite-dropdown.component.html',
  styleUrl: './favorite-dropdown.component.scss',
  imports: [RouterLink],
})
export class FavoriteDropdownComponent {
  ressources: Ressource[] = [];

  private userService: UserService = inject(UserService);

  constructor() {
    effect(() => {
      const me = this.userService.me$();
      this.ressources = this.sortFavoriteResources(me?.preferences?.favorites);
    });
  }

  private sortFavoriteResources(
    favorites: Record<string, string[]>,
  ): Ressource[] {
    if (!favorites) return [];

    return Object.entries(RESSOURCES_ICONS).reduce((acc, [key, icon]) => {
      if (favorites[key]) {
        const sortedFavoritesWithLink = favorites[key]
          .sort((a, b) => a.localeCompare(b))
          .map((name) => ({
            name,
            link: `/${key}/${encodeURIComponent(name)}`,
          }));
        acc.push({ name: key, icon, favorites: sortedFavoritesWithLink });
      }
      return acc;
    }, []);
  }
}
