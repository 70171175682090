import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

import { APP_FACTORY } from '@/factory';
import { ROUTES } from '@/routes';

import { AppComponent } from './app/app.component';
import { VersionsService } from './app/components/versions.service';
import { TurbineApiService } from './app/core/api.service';
import { ConfigurationService } from './app/core/configuration.service';
import { GlobalErrorHandler } from './app/core/global-error-handler';
import { TeamService } from './app/teams/teams.service';
import { UserService } from './app/users/user.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    BsModalService,
    provideRouter(ROUTES),
    {
      provide: APP_INITIALIZER,
      useFactory: APP_FACTORY,
      deps: [
        ConfigurationService,
        TurbineApiService,
        UserService,
        TeamService,
        VersionsService,
      ],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.log(err));
