import { Component, OnInit } from '@angular/core';

import { ThemeService } from '@/core/theme.service';

@Component({
  selector: 'turbine-logo',
  template: `<img
    id="turbine-logo"
    class="svg"
    src="/assets/logos/{{ logo }}.svg"
    alt="Turbine logo"
  />`,
  styles: [
    `
      .svg {
        display: inline-block;
        height: 1.5em;
        fill: #343a40;
        vertical-align: middle;
        width: 6.25em;
      }
    `,
  ],
  standalone: true,
})
export class LogoComponent implements OnInit {
  public logo = 'turbine';

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    const theme = this.themeService.theme;
    if (theme === 'HALLOWEEN') {
      this.logo = 'turbine-halloween';
    }
  }
}
