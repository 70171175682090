import { AfterViewInit, Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfigurationService } from '@/core/configuration.service';
import { AUTH_COOKIE_LOGGED_AS } from '@/core/login.service';
import { UserService } from '@/users/user.service';

import { Alert } from './dialog';
import { DialogService } from './dialog.service';

@Component({
  selector: 'turbine-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
})
export class DialogComponent implements AfterViewInit {
  alertSubscription: Subscription;
  alerts: Alert[] = [];

  constructor(
    private dialogService: DialogService,
    private configurationService: ConfigurationService,
    private userService: UserService,
  ) {}

  ngAfterViewInit(): void {
    this.alertSubscription = this.dialogService.listenAlert((alert) =>
      this.onAlert(alert),
    );
    const loggedAs = this.configurationService.getCookie(AUTH_COOKIE_LOGGED_AS);
    if (loggedAs) {
      this.userService.getUser(loggedAs).subscribe((user) => {
        this.dialogService.alert(
          new Alert(
            `You are temporarily logged with the same rights that ${user.name} (${loggedAs}). When you’re done, <a href="/logout">logout</a> and login again to retrieve your own rights.`,
            'info',
            -1,
          ),
        );
      });
    }
  }

  onAlert(alert: Alert): void {
    this.alerts.unshift(alert);
    if (alert.time >= 0) {
      setTimeout(() => this.closeAlert(alert), alert.time);
    }
  }

  closeAlert(alert: Alert): void {
    const index = this.alerts.indexOf(alert);
    if (index >= 0) {
      this.alerts.splice(index, 1);
    }
  }
}
