import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { delay, of, Subscription } from 'rxjs';

import { Alert } from './dialog/dialog';
import { DialogService } from './dialog/dialog.service';
import { LoginService } from './login.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private reloadMessage: Subscription;
  private hasExpirationMessage = false;

  constructor(
    private dialogService: DialogService,
    private loginService: LoginService,
    private zone: NgZone,
    private router: Router,
  ) {}

  handleError(error: any): void {
    if (
      error.status === 401 &&
      error.msg === 'Requires login' &&
      !this.loginService.isLoggedIn
    ) {
      if (!this.hasExpirationMessage) {
        this.dialogService.alert(
          Alert.custom(
            `Your session has expired, you must re-authenticate.`,
            'info',
          ),
        );
        this.hasExpirationMessage = true;
      }
      this.reloadMessage = of(false)
        .pipe(delay(4000))
        .subscribe(() => {
          this.reloadMessage.unsubscribe();
          if (!this.loginService.isLoggedIn) {
            this.hasExpirationMessage = false;
            this.zone.run(() => this.router.navigate(['/login']));
          }
        });
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      this.dialogService.alert(
        Alert.custom(
          `A new version of Turbine is available, <br /><strong>the page will reload</strong>.`,
          'warning',
        ),
      );

      this.reloadMessage = of(false)
        .pipe(delay(4000))
        .subscribe(() => {
          console.log(
            "GlobalErrorHandler :: A new version of Turbine is available, let's reload the page",
          );
          this.reloadMessage.unsubscribe();
          window.location.reload();
        });
    }
  }
}
