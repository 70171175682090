<div class="cover-container d-flex flex-wrap w-100 vh-100 p-3 justify-content-center text-center align-items-center">
  <main role="main" class="inner cover">
    <h1 class="cover-heading text-primary">
      <turbine-spinner-box customCls="color-primary my-2 mx-auto" />
      Your environment is waking up
    </h1>
    <p class="lead">
      In a couple of minutes, your application and its components will be up! Access it from this link:<br />
      <a href="{{ originUrl }}" class="btn btn-link" target="_blank" rel="noopener">
        <i class="fas fa-external-link-alt mr-2" aria-hidden="true"></i>Go to {{ originUrl }}
      </a>
    </p>
    <p class="lead">
      To view the details of the waking up process, log into Turbine:<br />
      <a routerLink="/environments/{{ environment_name }}/view/IDLING" class="btn btn-link">
        <i class="fas fa-sign-in-alt" aria-hidden="true"></i>
        Log in to view details
      </a>
    </p>
  </main>
</div>
