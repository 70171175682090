<nav class="navbar navbar-expand flex-column flex-md-row flex-sm-row app-navbar" role="navigation" title="Turbine banner">
  <a class="navbar-brand" href="#" title="Turbine {{ turbineEnvType }}">
    <turbine-logo class="ml-2" />
    <span class="badge-envtype">
      @if (turbineEnvType === 'prep') {
        <span class="badge badge-prep ml-2">prep</span>
      }
      @if (turbineEnvType !== 'prod' && turbineEnvType !== 'prep') {
        <span class="badge badge-dark ml-2">{{ turbineEnvType }}</span>
      }
    </span>
    <turbine-api-monitor />
  </a>

  <div class="flex-grow-1">
    <div class="navbar-nav ml-auto justify-content-end">
      <turbine-favorite-dropdown />

      <button
        class="btn btn-primary text-white d-flex justify-content-center align-items-center position-relative mr-2 news_pane--button"
        [class.active]="!newsPaneCollapsed"
        [class.unread]="hasUnreadNews"
        (click)="toggleNewsPane()"
        aria-label="Turbine news"
      >
        <i class="fas fa-bell" aria-hidden="true"></i>
      </button>

      <!-- TODO: display only if there's news -->
      <div class="user-menu dropdown">
        <a
          class="btn btn-sm btn-link dropdown-toggle user-initials"
          [attr.data-letters]="user_initials"
          aria-label="User menu"
          href="#"
          role="button"
          id="dropdownUserMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
        </a>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownUserMenuLink">
          <h6 class="dropdown-header text-uppercase">Turbine</h6>
          <button type="button" class="dropdown-item" (click)="openPrefsModal()" title="Open modal: user preferences">
            <i class="fas fa-cog" aria-hidden="true"></i> Preferences
          </button>
          <a
            class="dropdown-item"
            href="/support"
            routerLinkActive="active"
            (click)="
              trackingService.gtmSend({
                event: 'gaEvent',
                type: 'root_view',
                category: 'App',
                action: 'click',
                label: 'Header',
                value: 'Support'
              })
            "
            title="Get help"
          >
            <i class="far fa-question-circle" aria-hidden="true"></i> Support
          </a>
          <div class="dropdown-divider"></div>
          @if (!me) {
            <button type="button" class="dropdown-item" (click)="login()"><i class="fas fa-sign-in-alt" aria-hidden="true"></i> Log in</button>
          } @else {
            <button type="button" class="dropdown-item" (click)="logout()" title="Logout {{ me.name }}">
              <i class="fas fa-sign-out-alt" aria-hidden="true"></i> Log out
            </button>
          }
        </div>
      </div>
    </div>
  </div>
</nav>
