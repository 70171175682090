import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';

import { Alert } from '@/core/dialog/dialog';
import { DialogService } from '@/core/dialog/dialog.service';
import { CreateTool } from '@/model/model';

import {
  JSON_HEADERS,
  type PatchOptions,
  type PostOptions,
  type PutOptions,
  req_opts,
} from './api';
import { URLs } from './configuration';
import { LoginService } from './login.service';

class ErrorHandlerConfig {
  allow404?: boolean;
  defaultValue?: any;
  allowAlert?: boolean;
}

@Injectable({ providedIn: 'root' })
export class TurbineApiService {
  isRequesting = false;

  callCount = 0;

  etagCache = {};

  // initialized by app module APP_FACTORY
  urls = null as URLs;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private dialogService: DialogService,
  ) {}

  getJson(
    uri: string,
    ifnull: any = null,
    allowNotFound: boolean = false,
    headers: { [name: string]: string }[] = null,
  ): Observable<any> {
    const configHandler: ErrorHandlerConfig = {
      allow404: allowNotFound,
      defaultValue: ifnull,
      allowAlert: true,
    };

    let mergedHeaders = req_opts(JSON_HEADERS);
    if (headers != null) {
      const getHeaders = headers.slice();
      getHeaders.push(JSON_HEADERS);
      mergedHeaders = req_opts(...getHeaders);
    }

    this.onRequestStarted();
    return this.http
      .get(uri, {
        headers: mergedHeaders,
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map((data) => this.cacheSave(uri, data)),
        map((data) => data.body),
        catchError(this.getHandleError(configHandler)),
        finalize(() => this.onRequestStopped()),
      );
  }

  postJson(
    uri: string,
    data: any = '',
    options?: PostOptions,
  ): Observable<any> {
    const { headers, observe, allowAlert, authoring }: PostOptions = {
      headers: null,
      observe: 'body',
      allowAlert: true,
      authoring: true,
      ...options,
    };

    const configHandler: ErrorHandlerConfig = {
      allowAlert,
    };

    let mergedHeaders = req_opts(JSON_HEADERS);
    if (headers != null) {
      const getHeaders = headers.slice();
      getHeaders.push(JSON_HEADERS);
      mergedHeaders = req_opts(...getHeaders);
    }
    if (authoring) {
      this.setAuthoring('create', data);
    }

    this.onRequestStarted();
    return this.http
      .post(uri, data, {
        headers: mergedHeaders,
        observe: observe as any,
        withCredentials: true,
      })
      .pipe(
        catchError(this.getHandleError(configHandler)),
        finalize(() => this.onRequestStopped()),
      );
  }

  patchJson(
    uri: string,
    data: any = '',
    options?: PatchOptions,
  ): Observable<any> {
    const { authoring }: PatchOptions = {
      authoring: true,
      ...options,
    };

    this.onRequestStarted();
    if (authoring) {
      this.setAuthoring('update', data);
    }
    return this.http
      .patch(uri, data, {
        headers: this.loginService.headers(JSON_HEADERS),
        withCredentials: true,
      })
      .pipe(
        catchError(this.getHandleError()),
        finalize(() => this.onRequestStopped()),
      );
  }

  private _putJson(
    uri: string,
    data: any = '',
    authoring: boolean,
  ): Observable<any> {
    this.onRequestStarted();

    const headers = JSON_HEADERS;
    const etag = this.etagCache[uri];
    if (etag) {
      headers['if-match'] = etag;
    }

    if (authoring) {
      this.setAuthoring('update', data);
    }

    return this.http
      .put(uri, data, {
        headers: this.loginService.headers(headers),
        observe: 'response',
        withCredentials: true,
      })
      .pipe(
        map((resp) => this.cacheSave(uri, resp)),
        map((resp) => resp.body),
        catchError(this.getHandleError()),
        finalize(() => this.onRequestStopped()),
      );
  }

  putJson(uri: string, data: any = '', options?: PutOptions): Observable<any> {
    const { etag, authoring }: PutOptions = {
      etag: true,
      authoring: true,
      ...options,
    };

    if (etag && !this.hasEtagFor(uri)) {
      return this.getJson(uri).pipe(
        mergeMap(() => this._putJson(uri, data, authoring)),
      );
    }

    return this._putJson(uri, data, authoring);
  }

  delete(uri: string): Observable<any> {
    this.onRequestStarted();
    return this.http
      .delete(uri, {
        headers: this.loginService.headers(JSON_HEADERS),
        withCredentials: true,
      })
      .pipe(
        catchError(this.getHandleError()),
        finalize(() => this.onRequestStopped()),
      );
  }

  cacheSave(uri: string, data: HttpResponse<any>): any {
    const etag = data.headers.get('Etag');
    if (etag) {
      this.etagCache[uri] = etag;
    }

    return data;
  }

  getHandleError(
    config: ErrorHandlerConfig = {
      allowAlert: true,
    },
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return (e: HttpResponse<any>): Observable<any> => {
      let custom_msg;
      try {
        if (e['error'] && e['error']['error_description']) {
          custom_msg = e['error']['error_description'];
          if (e['error']['error_details']) {
            const details = e['error']['error_details'];
            for (const detail of details) {
              custom_msg += `<br>${detail.field}: ${detail.description}`;
            }
          }
        } else if (e['message']) {
          custom_msg = e['message'];
        }
      } catch (error) {}
      if (e.status === 401) {
        that.loginService.resetLogin();
        return observableThrowError(that.errorWith(e.status, 'Requires login'));
      } else if (e.status === 403) {
        const msg = custom_msg || 'You are not allowed to perform this action!';
        if (config.allowAlert) {
          that.dialogService.alert(Alert.error10s(msg));
        }
        return observableThrowError(that.errorWith(e.status, msg));
      } else if (e.status === 400) {
        const msg = custom_msg || `Invalid action!<br>${e.toString()}`;
        if (config.allowAlert) {
          that.dialogService.alert(Alert.error10s(msg));
        }
        return observableThrowError(that.errorWith(e.status, msg));
      } else if (e.status === 404) {
        if (config.allow404) {
          return of(config.defaultValue);
        }
        const msg = custom_msg || `Resource not found!<br>${e.toString()}`;
        return observableThrowError(that.errorWith(e.status, msg));
      } else if (e.status === 412) {
        const msg = `Resource has been modified since last access. Please reload your page and retry.`;
        if (config.allowAlert) {
          that.dialogService.alert(Alert.error10s(msg));
        }
        return observableThrowError(that.errorWith(e.status, msg));
      } else if (e.status === 0) {
        const msg = custom_msg || 'The Turbine API seems to be down!';
        if (config.allowAlert) {
          that.dialogService.alert(Alert.error10s(msg));
        }
        return observableThrowError(that.errorWith(e.status, msg));
      } else {
        const msg =
          custom_msg ||
          `Turbine API error (HTTP ${e.status})!<br>${e.toString()}`;
        if (config.allowAlert) {
          that.dialogService.alert(Alert.error10s(msg));
        }
        return observableThrowError(that.errorWith(e.status, msg));
      }
    };
  }

  hasEtagFor(resourceURI: string): boolean {
    return !!this.etagCache[resourceURI];
  }

  errorWith(status: number, msg: string) {
    return {
      status,
      msg,
    };
  }

  private setAuthoring(
    on: 'update' | 'create',
    data: Record<string, unknown>,
  ): void {
    if (!data) return;
    data.updatedBy = CreateTool.UI;
    if (on === 'create') {
      data.createdBy = CreateTool.UI;
    }
  }

  private onRequestStarted() {
    this.callCount++;
    this.isRequesting = this.callCount > 0;
  }

  private onRequestStopped() {
    this.callCount--;
    this.isRequesting = this.callCount > 0;
  }
}
