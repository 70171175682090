import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { ModalContent } from '@/core/modal/modal';

import { UserService } from '../user.service';

const DEFAULT_VALUES = {
  'editor.theme': 'light',
  'deployment.confirmation': true,
};

interface UserPreferencesForm {
  editorTheme: FormControl<string>;
  deploymentConfirmation: FormControl<boolean>;
}

@Component({
  standalone: true,
  selector: 'turbine-user-preferences',
  templateUrl: './user-preferences.component.html',
  imports: [ReactiveFormsModule],
})
export class UserPreferencesComponent implements OnInit, ModalContent {
  @Output() onComponentUpdated = new EventEmitter();
  @Output() isLoading = new EventEmitter<boolean>();

  form: FormGroup<UserPreferencesForm>;
  private userService: UserService = inject(UserService);

  ngOnInit(): void {
    const userPreferences = this.userService.me?.preferences;
    this.form = new FormGroup<UserPreferencesForm>({
      editorTheme: new FormControl<string>(
        userPreferences?.['editor.theme'] || DEFAULT_VALUES['editor.theme'],
        Validators.required,
      ),
      deploymentConfirmation: new FormControl<boolean>(
        userPreferences?.['deployment.confirmation'] !== undefined
          ? userPreferences?.['deployment.confirmation']
          : DEFAULT_VALUES['deployment.confirmation'],
        Validators.required,
      ),
    });
  }

  modalChildAction(): void {
    this.isLoading.emit(true);
    this.userService
      .createOrUpdatePreferences({
        'editor.theme': this.form.value.editorTheme,
        'deployment.confirmation': this.form.value.deploymentConfirmation,
      })
      .subscribe(() => this.onComponentUpdated.emit());
  }
}
