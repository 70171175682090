import { Component, EventEmitter, inject, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';

import { ConfigurationService } from '@/core/configuration.service';
import { Alert } from '@/core/dialog/dialog';
import { DialogService } from '@/core/dialog/dialog.service';
import type { ModalContent } from '@/core/modal/modal';
import { NotificationService } from '@/core/notification.service';
import { ShowErrorsComponent } from '@/core/show-errors.component';
import { UserService } from '@/users/user.service';

@Component({
  standalone: true,
  selector: 'turbine-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  imports: [ReactiveFormsModule, ShowErrorsComponent],
})
export class FeedbackModalComponent implements ModalContent {
  @Output() onComponentUpdated = new EventEmitter<void>();

  feedbackControl = new FormControl<string>('', Validators.required);
  isSubmited: boolean = false;

  private notificationService = inject(NotificationService);
  private userService = inject(UserService);
  private dialogService = inject(DialogService);
  private configurationService = inject(ConfigurationService);

  modalChildAction(): void {
    this.isSubmited = true;
    if (this.feedbackControl.invalid) return;

    const { notificationFeedbackId, notificationFeedbackSlackChannelId } =
      this.configurationService.conf.turbine;

    this.notificationService
      .sendMessage(notificationFeedbackId, 'SLACK', {
        recipients: [notificationFeedbackSlackChannelId],
        template_vars: {
          ldap: this.userService.me.id,
          date: new Date().toLocaleString('fr-FR'),
          feedback: this.feedbackControl.value,
        },
      })
      .subscribe({
        complete: () => {
          this.dialogService.alert(
            Alert.ok3s('Your feedback has been sent to the team.'),
          );
          this.onComponentUpdated.emit();
        },
        error: () => {
          this.dialogService.alert(
            Alert.error10s('An error occurred while sending your feedback.'),
          );
        },
      });
  }
}
