<div>
  <h3>{{ news.title }}</h3>
  <p class="m-0" [innerHTML]="news.description | safeHTML"></p>
  @for (attachment of news.attachments; track attachment) {
    <p class="m-0">
      @if (attachment.type === 'TEXT') {
        {{ attachment.text }}
      }
      @if (attachment.type === 'URL') {
        <a class="btn btn-sm btn-primary m-1" target="_blank" rel="noopener" [href]="attachment.url" [title]="attachment.text">{{ attachment.text }}</a>
      }
      @if (attachment.type === 'IMAGE') {
        <img style="max-width: 100%; margin: 0.5rem 0" [src]="attachment.url" [title]="attachment.text" [alt]="attachment.text" />
      }
    </p>
  }
  <span class="small news_pane__item--date" [title]="news.published_at | date: 'fullDate'">{{ news.published_at | relativeTime }}</span>
</div>
