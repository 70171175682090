import { HttpHeaders } from '@angular/common/http';

export const NO_CACHE_HEADERS = {
  'Cache-Control': 'no-cache',
  Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
  //"Pragma": "no-cache"
};

export const JSON_HEADERS = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: 'application/json; charset=utf-8',
};

const header_merge = (...headers: { [name: string]: string }[]) =>
  Object.assign({}, ...headers);

export const req_opts = (...headers: { [name: string]: string }[]) =>
  new HttpHeaders(header_merge(...headers));

export interface PutOptions {
  etag?: boolean;
  authoring?: boolean;
}

export interface PatchOptions {
  authoring?: boolean;
}

export interface PostOptions {
  headers?: { [name: string]: string }[];
  observe?: string;
  allowAlert?: boolean;
  authoring?: boolean;
}
