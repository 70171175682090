import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'turbine-not-found',
  template: '',
  standalone: true,
})
export class NotFoundComponent {
  constructor(private router: Router) {
    console.error('This page is not yet available or does not exist!');
    this.router.navigate(['/']);
  }
}
