import { Injectable } from '@angular/core';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({ providedIn: 'root' })
export class TrackingService {
  gtmSend(obj) {
    try {
      window.dataLayer.push(obj);
    } catch (error) {}
  }
}
