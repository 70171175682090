import { datadogRum } from '@datadog/browser-rum';
import { throwError as observableThrowError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

import { VersionsService } from './components/versions.service';
import { TurbineApiService } from './core/api.service';
import { ConfigurationService } from './core/configuration.service';
import { initUserMe } from './initUserMe';
import { TeamService } from './teams/teams.service';
import { UserService } from './users/user.service';

const initDatadogRUM = (
  envType: string = 'local',
  version: string = 'local',
) => {
  datadogRum.init({
    applicationId: '35c775c3-2777-4cd0-8231-27137208151c',
    clientToken: 'pub611a629360a88153a4e2a708877a645e',
    site: 'datadoghq.eu',
    service: 'turbine-ui',
    env: envType,
    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    // sampleRate must be 100 in order to have accurate analytics data
    sessionSampleRate: 100,
    sessionReplaySampleRate: envType === 'prod' ? 50 : 0,
    trackUserInteractions: true,
    // Session Replay
    defaultPrivacyLevel: 'allow',
  });

  // Session Replay
  datadogRum.startSessionReplayRecording();
};

export const APP_FACTORY =
  (
    configurationService: ConfigurationService,
    apiService: TurbineApiService,
    userService: UserService,
    // websocketModelService: IntercomEventService,
    teamService: TeamService,
    versionsService: VersionsService,
  ): (() => Promise<boolean>) =>
  () =>
    new Promise((resolve) => {
      configurationService
        .getConfiguration()
        // handle conf loading errors
        .pipe(
          catchError((error) => {
            resolve(false);
            return observableThrowError(
              error || 'Server error during configuration loading',
            );
          }),
          // setup services
          flatMap((conf) => {
            // initialize conf
            configurationService.conf = conf;
            // intialize API urls
            apiService.urls = configurationService.conf.turbine.api;
            versionsService.artifactory_url =
              configurationService.conf.artifactory.docker.repository.baseUrl;
            // intialize Real User Monitoring
            configurationService
              .getReleaseVersion()
              .subscribe((version: string) =>
                initDatadogRUM(
                  configurationService.conf.turbine.environment.type,
                  version,
                ),
              );
            // initialize Websockets
            // This is disabled because of fastly lack of websocket support
            // websocketModelService.initSocket();
            // then start current user initialization
            return initUserMe(userService, resolve, teamService);
          }),
        )
        .subscribe((teams) => {
          // intialize current user teams
          userService.me.teams = teams;
          // initialization done!
          resolve(true);
        });
    });
