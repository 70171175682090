import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TurbineApiService } from '@/core/api.service';
import { Tag } from '@/deployments/deployment';

@Injectable({ providedIn: 'root' })
export class VersionsService {
  artifactory_url: string;

  constructor(private api: TurbineApiService) {}

  getTagList(repo: string, path: string): Observable<Tag[]> {
    return this.api.getJson(
      `${this.api.urls.versions}/artifactory/${encodeURIComponent(
        this.artifactory_url,
      )}/repositories/${repo}/images/${encodeURIComponent(path)}/tags`,
    );
  }
}
