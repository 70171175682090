import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'spaceboy-says',
  templateUrl: './spaceboy-says.component.html',
  styleUrls: ['./spaceboy-says.component.scss'],
  standalone: true,
})
export class SpaceboySaysComponent {
  @Input() mode: 'classic' | 'hero' | 'flex' | 'inline' = 'classic';
  @Input() customCls: string;
  @Input() statusRole: string;
}
