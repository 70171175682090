@if (alerts.length > 0) {
  <div class="global-alerts clearfix">
    <div class="glass"></div>
    @for (alert of alerts; track alert) {
      <div class="alert alert-{{ alert.customStyle }} fade in">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert(alert)">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="content" [innerHTML]="alert.message"></div>
      </div>
    }
  </div>
}
