<turbine-drawer title="News" titleIcon="" col="col-4" name="news" [isDrawerCollapsed]="isNewsPaneCollapsed" (isCollapsed)="closeNewsPane()">
  <nav class="nav justify-content-end" title="News management actions">
    <a
      class="btn btn-link m-2 p-0"
      routerLink="/news"
      title="Manage the news"
      (click)="closeNewsPane()"
      [turbineACL]="{ resource: '_system', verbs: ['administrate'], domain: '/' }"
      >Manage</a
    >
    <button class="btn btn-link m-2 p-0" title="Refresh the news list" (click)="reloadNews()">Refresh</button>
    <button class="btn btn-link m-2 p-0" [class.disabled]="!has_unread_news" title="Mark all as read" (click)="markAllNewsAsRead()">Mark all as read</button>
  </nav>

  @if (!news) {
    <div class="w-100">
      <turbine-spinner-box customCls="my-4 mx-auto" />
    </div>
  }

  @for (n of news; track $index) {
    <div class="news_pane__item">
      <div class="d-flex align-items-start justify-content-between">
        <div (click)="markNewsAsRead(n)">
          <turbine-news [news]="n" />
        </div>
        <div>
          @if (n.acked) {
            <button class="news-action__btn news-action__btn--default news-action__btn--read" title="Mark as unread" (click)="markNewsAsUnread(n)">
              Mark as unread
            </button>
          } @else {
            <button class="news-action__btn news-action__btn--default news-action__btn--unread" title="Mark as read" (click)="markNewsAsRead(n)">
              Mark as read
            </button>
          }
        </div>
      </div>
    </div>
  }
  @if (news && news.length === 0) {
    <spaceboy-says mode="hero" customCls="w-100" role="status">
      <p class="lead">No news for today, maybe tomorrow!</p>
      <div class="alert alert-info">
        <i class="far fa-lightbulb mr-2" aria-hidden="true"></i> Stay tuned and find out more about the latest features and news.
      </div>
    </spaceboy-says>
  }
</turbine-drawer>
