import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Configuration } from './configuration';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
  // initialized by app module APP_FACTORY
  conf: Configuration = null;
  releaseVersion: string = null;

  constructor(private http: HttpClient) {}

  getConfiguration(): Observable<Configuration> {
    if (this.conf == null) {
      const confVersion = Date.now().toString(); // avoid caching
      return this.http.get(`/assets/conf.json?_version=${confVersion}`).pipe(
        map((response) => {
          this.conf = response as Configuration;
          return this.conf;
        }),
      );
    } else {
      return of(this.conf);
    }
  }

  getReleaseVersion(): Observable<string> {
    if (this.releaseVersion == null) {
      const confVersion = Date.now().toString(); // avoid caching
      return this.http.get(`/assets/version.json?_version=${confVersion}`).pipe(
        map((response: { version: string }) => {
          this.releaseVersion = response.version;
          return this.releaseVersion;
        }),
      );
    } else {
      return of(this.releaseVersion);
    }
  }

  setCookie(
    name: string,
    value: string,
    expireDays: number,
    path: string = '/',
  ): void {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  getCookie(name: string): string {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    } else {
      return null;
    }
  }

  removeCookie(name: string): void {
    this.setCookie(name, '', 0);
  }

  setStorageItem(
    name: string,
    value: string,
    expireDays: number,
    path: string = '/',
  ): void {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);

    if (!window.localStorage) {
      this.setCookie(name, value, expireDays, path);
    }
    const item = {
      value,
      expires: d.toUTCString(),
    };
    window.localStorage.setItem(name, JSON.stringify(item));
  }

  getStorageItem(name: string): string {
    if (!window.localStorage) {
      return this.getCookie(name);
    }

    const itemStr = window.localStorage.getItem(name);
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    if (!item.hasOwnProperty('value')) {
      return itemStr;
    }

    const now = new Date();
    const itemExpires = new Date(item.expires);

    if (now.getTime() > itemExpires.getTime()) {
      // If item is expired, delete the item from storage and return null
      window.localStorage.removeItem(name);
      return null;
    }
    return item.value;
  }

  removeStorageItem(name: string): void {
    if (!window.localStorage) {
      return this.removeCookie(name);
    }
    window.localStorage.removeItem(name);
  }
}
