import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { TurbineApiService } from '@/core/api.service';

import { Acknowledgement } from './news';

@Injectable({ providedIn: 'root' })
export class AcknowledgementService {
  constructor(private api: TurbineApiService) {}

  markAllAsRead(
    user_id: string,
    resource_type: string,
    resource_ids: string[],
  ): Observable<Acknowledgement[]> {
    // TODO reimplement based on a future new batch endpoint
    return forkJoin(
      resource_ids.map((id) => this.markAsRead(user_id, resource_type, id)),
    );
  }

  markAsRead(
    user_id: string,
    resource_type: string,
    resource_id: string,
  ): Observable<Acknowledgement> {
    return this.api.postJson(
      `${this.api.urls.auth}/users/${user_id}/acknowledgements/${resource_type}/${resource_id}`,
      null,
    );
  }

  markAsUnread(
    usr_id: string,
    resource_type: string,
    resource_id: string,
  ): Observable<any> {
    return this.api.delete(
      `${this.api.urls.auth}/users/${usr_id}/acknowledgements/${resource_type}/${resource_id}`,
    );
  }
}
