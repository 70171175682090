import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { TrackingService } from '@/app-tracking.service';
import { LogoComponent } from '@/shared-ui/logo.component';
import { UserDetails } from '@/users/user';
import { UserService } from '@/users/user.service';
import { UserPreferencesComponent } from '@/users/user-preferences/user-preferences.component';

import { TurbineApiMonitorComponent } from '../api-monitor.component';
import { ConfigurationService } from '../configuration.service';
import { FavoriteDropdownComponent } from '../favorite/favorite-dropdown.component';
import { LoginService } from '../login.service';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'turbine-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    LogoComponent,
    TurbineApiMonitorComponent,
    FavoriteDropdownComponent,
  ],
})
export class HeaderComponent implements OnInit {
  @Input() layout = '';
  @Input() newsPaneCollapsed: boolean;
  @Input() hasUnreadNews: boolean;

  @Output() newsPaneChange = new EventEmitter();

  public turbineEnvType = '';
  public me: UserDetails;
  public user_initials = '?';

  constructor(
    private router: Router,
    private configurationService: ConfigurationService,
    private loginService: LoginService,
    private userService: UserService,
    private modalService: ModalService,
    public trackingService: TrackingService,
  ) {
    this.me = this.userService.me;
    this.configurationService.getConfiguration().subscribe((conf) => {
      this.turbineEnvType = conf.turbine.environment.type;
    });
  }

  ngOnInit() {
    if (this.me) {
      const names = this.me.name.split(' ');
      if (names.length >= 2) {
        this.user_initials = names[0].charAt(0) + names[1].charAt(0);
      }
    }
  }

  toggleNewsPane() {
    this.newsPaneChange.emit(0);
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'App',
      action: 'click',
      label: 'Header',
      value: 'Toggle News Pane',
    });
  }

  openPrefsModal() {
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'App',
      action: 'click',
      label: 'Header',
      value: 'Preferences',
    });

    const modalRef = this.modalService.showModal({
      class: 'modal-lg',
      initialState: {
        title: `Preferences`,
        child: UserPreferencesComponent,
        mainActionBtnName: 'Close and reload',
        mainActionBtnStyle: 'primary',
      },
    });
    modalRef.content.onDone.subscribe(() => {
      modalRef.hide();
      document.location.reload();
    });
  }

  login(): void {
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'App',
      action: 'click',
      label: 'Header',
      value: 'Login',
    });
    this.loginService.resetLogin();
    if (!this.userService.me) {
      this.router.navigate(['login']);
    }
  }

  logout(): void {
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'App',
      action: 'click',
      label: 'Header',
      value: 'Logout',
    });
    this.loginService.logout().subscribe((loggedOut) => {
      if (loggedOut) {
        this.router.navigate(['login']).then(() => {
          document.location.reload();
        });
      }
    });
  }
}
