import { Component, inject, OnInit } from '@angular/core';

import { UserService } from '@/users/user.service';

declare global {
  interface Window {
    Tangram;
  }
}

@Component({
  standalone: true,
  selector: 'turbine-user-feedback',
  template: '',
})
export class UserFeedbackComponent implements OnInit {
  private userService = inject(UserService);

  ngOnInit() {
    new window.Tangram({
      productId: '8d3bcc1e-8655-4b03-9f58-e7118a424788',
      ...(this.userService.me && { userLdap: this.userService.me.id }),
    });
  }
}
