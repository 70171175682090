<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="cancelOrCloseModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  @if (content) {
    <div [innerHTML]="content"></div>
  } @else if (templateRef) {
    <ng-container *ngTemplateOutlet="templateRef"></ng-container>
  }
  <div #modalContent (onComponentUpdated)="onComponentUpdated($event)"></div>
</div>
@if (mainActionBtnName) {
  <div class="modal-footer">
    @if (confirmWithEnter) {
      <p class="flex-grow-1 text-muted">Press <code>Enter</code> to confirm</p>
    }
    @if (cancelBtnName) {
      <button type="button" class="btn btn-sm btn-link text-muted" (click)="cancelOrCloseModal()">
        {{ cancelBtnName }}
      </button>
    }
    <button type="button" class="btn btn-sm btn-{{ mainActionBtnStyle }}" (click)="modalChildAction()" [disabled]="isLoading">
      {{ isLoading ? mainActionBtnIsLoadingName : mainActionBtnName }}
    </button>
  </div>
}
