import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TurbineApiService } from '@/core/api.service';
import { ModalService } from '@/core/modal/modal.service';

import { HeadLineNewsId, News, NewsData, UserNews } from './news';

@Injectable({ providedIn: 'root' })
export class NewsService {
  constructor(
    private api: TurbineApiService,
    private modalService: ModalService,
  ) {}

  getHeadLine(): Observable<News> {
    return this.get(HeadLineNewsId);
  }

  get(news_id: string): Observable<News> {
    return this.api.getJson(`${this.api.urls.auth}/news/${news_id}`).pipe(
      map((n) => this._fixNews<News>(n)),
      catchError(() => of(null)),
    );
  }

  create(news: NewsData): Observable<News> {
    return this.api
      .postJson(`${this.api.urls.auth}/news`, news, { authoring: false })
      .pipe(map((n) => this._fixNews<News>(n)));
  }

  updateNews(news: News): Observable<News> {
    return this.api
      .putJson(`${this.api.urls.auth}/news/${news.id}`, news, {
        authoring: false,
      })
      .pipe(map((n) => this._fixNews<News>(n)));
  }

  deleteWithWarn(news: News, callBack: () => void): void {
    const modalRef = this.modalService.showModal({
      initialState: {
        title: `Delete the news?`,
        content: `Are you sure you want to delete the news <b>${news.title}</b>?`,
        confirmWithEnter: true,
        mainActionBtnName: 'Delete it',
        mainActionBtnStyle: 'danger',
      },
    });
    modalRef.content.onDone.subscribe(() => {
      this.delete(news.id).subscribe(() => {
        modalRef.hide();
        callBack();
      });
    });
  }

  delete(news_id: string): Observable<any> {
    return this.api.delete(`${this.api.urls.auth}/news/${news_id}`);
  }

  findAll(): Observable<News[]> {
    return this.api
      .getJson(`${this.api.urls.auth}/news`, [])
      .pipe(map((all) => all.map((n) => this._fixNews<News>(n))));
  }

  findForUser(limit: number = 5): Observable<UserNews[]> {
    return this.api
      .getJson(`${this.api.urls.auth}/users/me/news?limit=${limit}`, [])
      .pipe(map((all) => all.map((n) => this._fixNews<News>(n))));
  }

  _fixNews<T extends News>(news: T): T {
    if (news == null) {
      return news;
    }
    news.published_at = this._fixDate(news.published_at);
    news.created_at = this._fixDate(news.created_at);
    news.updated_at = this._fixDate(news.updated_at);
    if (news.attachments == null) {
      news.attachments = [];
    }
    return news;
  }

  _fixDate(v: any): Date {
    if (v == null) {
      return null;
    }
    switch (typeof v) {
      case 'string':
        return new Date(v);
      case 'number':
        return new Date(v);
      case 'object':
        return v;
    }
  }
}
