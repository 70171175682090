import { Component } from '@angular/core';

import { TurbineApiService } from './api.service';

@Component({
  selector: 'turbine-api-monitor',
  templateUrl: './api-monitor.component.html',
  styleUrls: ['./api-monitor.component.scss'],
  standalone: true,
})
export class TurbineApiMonitorComponent {
  state = { isInProgress: () => false };

  constructor(public apiService: TurbineApiService) {
    this.state.isInProgress = () => apiService.isRequesting;
  }
}
