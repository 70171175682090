import { Route } from '@angular/router';

import { AuthGuardService } from './core/auth-guard.service';
import { EnvironmentIdlingPublicStatusComponent } from './idling/environment-idling-public-status.component';
import { IdlingRouterComponent } from './idling/idling-router.component';
import { AppLayoutComponent } from './layouts/app-layout.component';
import { NotFoundComponent } from './not-found.component';
import { LogoutComponent } from './users/logout.component';

export const ROUTES: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: 'users/me' },
  {
    path: 'login',
    data: { page_type: 'root_view' },
    loadComponent: () =>
      import('./core/login.component').then((x) => x.LoginComponent),
  },
  {
    path: 'logout',
    data: { page_type: 'root_view' },
    component: LogoutComponent,
  },
  {
    path: 'oauth/:provider/_callback',
    loadComponent: () =>
      import('./auth/oauth/oauth.component').then((x) => x.OAuthComponent),
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'users',
        loadChildren: () => import('./users/routes').then((m) => m.ROUTES),
      },
      {
        path: 'environments',
        loadChildren: () =>
          import('./environments/routes').then((m) => m.ROUTES),
      },
      {
        path: 'components',
        loadChildren: () => import('./components/routes').then((m) => m.ROUTES),
      },
      {
        path: 'templates',
        loadChildren: () => import('./templates/routes').then((m) => m.ROUTES),
      },
      {
        path: 'federations',
        loadChildren: () =>
          import('./federations/routes').then((m) => m.ROUTES),
      },
      {
        path: 'platforms',
        loadChildren: () => import('./platforms/routes').then((m) => m.ROUTES),
      },
      {
        path: 'teams',
        loadChildren: () => import('./teams/routes').then((m) => m.ROUTES),
      },
      {
        path: 'acl',
        loadChildren: () => import('./acl/routes').then((m) => m.ROUTES),
      },
      {
        path: 'domains',
        loadChildren: () => import('./domains/routes').then((m) => m.ROUTES),
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/routes').then((m) => m.ROUTES),
      },
      {
        path: 'jobs',
        loadChildren: () => import('./jobs/routes').then((m) => m.ROUTES),
      },
      {
        path: 'deployments',
        loadChildren: () =>
          import('./deployments/routes').then((m) => m.ROUTES),
      },
      {
        path: 'news',
        loadChildren: () => import('./users/news/routes').then((m) => m.ROUTES),
      },
      {
        path: 'deployments/:environment_name/:component_name/provisioning/review',
        canActivate: [AuthGuardService],
        loadComponent: () =>
          import('./change-detection/change-detection.component').then(
            (x) => x.ChangeDetectionComponent,
          ),
      },
      {
        path: 'environments/:environment_name/provisioning/review',
        canActivate: [AuthGuardService],
        loadComponent: () =>
          import('./change-detection/change-detection.component').then(
            (x) => x.ChangeDetectionComponent,
          ),
      },
    ],
  },
  {
    path: '',
    loadChildren: () => import('./public/routes').then((m) => m.ROUTES),
  },
  {
    path: '_idling_router',
    component: IdlingRouterComponent,
    data: { page_type: 'public_view' },
  },
  {
    path: 'idling/platforms/:platform_name/environments/:environment_name',
    component: EnvironmentIdlingPublicStatusComponent,
    data: { page_type: 'public_view' },
  },
  { path: '**', component: NotFoundComponent },
];
