import { Component, HostListener } from '@angular/core';

interface KonamiType {
  img: string;
  title: string;
}

const KONAMI_CODE: string[] = [
  'ArrowUp',
  'ArrowUp',
  'ArrowDown',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowLeft',
  'ArrowRight',
  'b',
  'a',
];

const choices: KonamiType[] = [
  {
    img: 'https://media3.giphy.com/media/ViDezACxZugPC/giphy.gif',
    title: 'I love using Turbine!',
  },
  {
    img: 'https://media.giphy.com/media/oLhNpslqjVm12/giphy.gif',
    title: 'The Turbine runner is so sweet this week!',
  },
  {
    img: 'https://media.giphy.com/media/citBl9yPwnUOs/giphy.gif',
    title: 'Blocked while using Turbine, ask help!',
  },
  {
    img: 'https://www.reactiongifs.com/r/ybrws.gif',
    title: 'I know you really want to deploy your container with Turbine!',
  },
  {
    img: 'https://media.giphy.com/media/ld1RKulOqeeaI/giphy.gif',
    title: 'Turbine users are the coolest people!',
  },
  {
    img: 'https://media.giphy.com/media/JpXSbun1ldcU7bW9l2/giphy.gif',
    title: 'Walter White is a Turbine admin!',
  },
  {
    img: 'https://media.giphy.com/media/JwLnNc3QRZzIA/giphy.gif',
    title: 'This guy is missing Turbine!',
  },
  {
    img: 'https://media.giphy.com/media/l4Ki4oD7OfUMZMGHu/giphy.gif',
    title: 'take on meeeeeeeeee',
  },
  {
    img: 'https://64.media.tumblr.com/809bcb918f91d0a4c8aa508f10324f69/tumblr_mlpzqxfSkR1rfjowdo1_500.gif',
    title: `Turbine, it's stronger than him!`,
  },
  {
    img: 'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdXQzMjFsZmU4OXVuODZydzBxbDFqNDVuZndsdGVkZ2xpbTNqeWJtMSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/sDcfxFDozb3bO/giphy.gif',
    title: 'Turbine is awesome!',
  },
  {
    img: 'https://media.giphy.com/media/nQDKSeRlIyfmw/giphy.gif',
    title: 'Turbine secrets lies here !!!',
  },
];

@Component({
  standalone: true,
  selector: 'turbine-konami',
  template: `@if (konami) {
    <div
      class="position-fixed bg-white text-center pt-5"
      style="inset:0;z-index:9999;"
      (click)="konami = null"
    >
      <h1>{{ konami.title }}</h1>
      <img
        class="mt-5"
        [src]="konami.img"
        width="400"
        alt="Konami code: a gif from the team"
      />
    </div>
  }`,
})
export class KonamiComponent {
  sequence: string[] = [];
  konami: KonamiType;

  private isKonamiCode(): boolean {
    return KONAMI_CODE.every(
      (code: string, index: number) => code === this.sequence[index],
    );
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key) {
      this.sequence.push(event.key);

      if (this.sequence.length > KONAMI_CODE.length) {
        this.sequence.shift();
      }

      if (this.isKonamiCode()) {
        const random = Math.floor(Math.random() * choices.length);
        this.konami = choices[random];
      }
    }
  }
}
