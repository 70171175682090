import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'turbine-idling-router',
  template: '',
  standalone: true,
})
export class IdlingRouterComponent {
  constructor(
    private router: Router,
    private routes: ActivatedRoute,
  ) {
    this.routes.queryParams.subscribe((params) => {
      const [platform, env_name, origin_url] = [
        params['platform'],
        params['environment'],
        params['idle_from'],
      ];

      this.router
        .navigate(['idling', 'platforms', platform, 'environments', env_name], {
          queryParams: { origin_url },
        })
        .then(() => null);
    });
  }
}
