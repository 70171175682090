import { Component, Input, OnInit } from '@angular/core';
import { of, Subject, timer } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

import { ModalService } from '@/core/modal/modal.service';

import { UserNews } from '../news/news';
import { NewsService } from '../news/news.service';
import { UserDetails } from '../user';
import { WhatsNewModalComponent } from './whatsnew-modal-content.component';

@Component({
  selector: 'turbine-whatsnew',
  template: '',
  styleUrls: ['whatsnew.component.scss'],
  standalone: true,
})
export class WhatsNewComponent implements OnInit {
  @Input() refresh_seconds;
  @Input() me;
  featureNews: UserNews;
  private reload = new Subject<void>();

  constructor(
    private newsService: NewsService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    timer(0, 1000 * this.refresh_seconds)
      .pipe(
        switchMap(() =>
          this.newsService.findForUser().pipe(catchError(() => of(null))),
        ),
        takeUntil(this.reload),
      )
      .subscribe({
        next: (news: UserNews[]) => {
          if (!news) {
            return;
          }
          this.featureNews = news.find(
            (n: any) => n.feature_announcement && !n.acked,
          );
          if (this.featureNews && this.modalService.getModalsCount() === 0) {
            this.openNewFeatureModal(this.featureNews, this.me);
          }
        },
        error: () => (this.featureNews = null),
      });
  }

  openNewFeatureModal(featureNews: UserNews, me: UserDetails): void {
    const modalRef = this.modalService.showModal({
      initialState: {
        title: "What's new on Turbine?",
        cancelBtnName: null,
        mainActionBtnName: 'Mark as read',
        mainActionOnClose: true, // markAsRead on click on the close button of the modal
        child: WhatsNewModalComponent,
        childInputs: { news: featureNews, me },
      },
      class: 'modal-lg feature-announce',
    });
    modalRef.content.onDone.subscribe(() => {
      modalRef.hide();
    });
  }
}
