import { TurbineTag } from '@/components/component';
import type { ACL } from '@/users/user';

export interface EngineModel {
  model: ModelMeta;
  metadata: ModelAuthoring;
}

export interface MultipleDomainsEngineModel {
  model: ModelWithMultipleDomainsMeta;
  metadata: ModelAuthoring;
  provisioning: Provisioning;
  tags?: TurbineTag[];
}

interface Provisioning {
  template?: string;
}

export interface TeamModel {
  name: string;
  domain?: string;
  members?: [];
}

interface ModelMeta {
  description: string;
  name: string;
  display_name: string;
  type: string;
  version: number;
  domain: string;
  metadata: ModelMetadata;
}

export interface ModelWithMultipleDomainsMeta {
  name: string;
  display_name: string;
  type: string;
  version: number;
  domains: string[];
  acls: ACL[];
  metadata: ModelMetadata;
  description: string;
}

export interface ModelMetadata {
  labels?: { [key: string]: string };
  annotations?: { [key: string]: string };
}

export enum CreateTool {
  API = 'turbine-api',
  Client = 'turbine-client',
  UI = 'turbine-ui',
  Terraform = 'terraform-provider-turbine',
}

export interface ModelAuthoring {
  creator: string;
  'creation-date': Date;
  'last-editor': string;
  'last-update': Date;
  createdBy: `${CreateTool}` | null;
  updatedBy: `${CreateTool}` | null;
}
export interface ModelQuery {
  search?: string;
  type?: string;
  name?: string;
  display_name?: string;
  domains?: string[];
  domain?: string;
  ldap?: string;
  tag?: string;
  template?: string;
}

export const modelSort = (a, b): number =>
  (a.model ? a.model.name : a.name).localeCompare(
    b.model ? b.model.name : b.name,
  );
