import { Component, Input } from '@angular/core';

@Component({
  selector: 'turbine-spinner-box',
  template: `<div class="lds-default {{ customCls }}"></div>
    <p [innerHtml]="content"></p>`,
  styles: [
    `
      .lds-default {
        display: block;
        position: relative;
        width: 80px;
        height: 80px;
        background-size: contain;
        background-color: #adb5bd;
        -webkit-mask-image: url('/assets/turbine-box--black.svg');
        mask-image: url('/assets/turbine-box--black.svg');
      }
      p {
        text-align: center;
        color: #adb5bd;
      }
      .lds-default.color-primary {
        background-color: #007bff;
      }
      .lds-default.color-black {
        background-color: #000;
      }
    `,
  ],
  standalone: true,
})
export class SpinnerBoxComponent {
  @Input() customCls = '';
  @Input() content = '';
}
