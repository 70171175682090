import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TrackingService } from '@/app-tracking.service';
import { SanitizeSafeHtmlPipe } from '@/core/dialog/safe-html.pipe';
import { ModalContent } from '@/core/modal/modal';

import { AcknowledgementService } from '../news/acknowledgement.service';
import { NewsAttachment, UserNews } from '../news/news';
import { UserDetails } from '../user';

@Component({
  selector: 'turbine-whatsnew-modal',
  templateUrl: './whatsnew-modal-content.component.html',
  standalone: true,
  imports: [SanitizeSafeHtmlPipe],
})
export class WhatsNewModalComponent implements OnInit, ModalContent {
  @Input() refresh_seconds;
  @Input() me;

  @Output()
  onComponentUpdated = new EventEmitter();

  news: UserNews;
  newsLinks = [];
  newsHasImage = false;

  constructor(
    private acknowledgementService: AcknowledgementService,
    private trackingService: TrackingService,
  ) {}

  ngOnInit() {
    if (!this.news?.attachments) return;
    this.newsLinks = this.news.attachments.filter(
      (n: NewsAttachment) => n.type === 'URL',
    );
    this.newsHasImage =
      this.news.attachments.findIndex(
        (n: NewsAttachment) => n.type === 'IMAGE',
      ) > -1;
  }

  modalChildAction() {
    this.markNewsAsRead(this.news, this.me);
  }

  markNewsAsRead(news: UserNews, me: UserDetails) {
    if (news.acked || !me) {
      return;
    }
    this.acknowledgementService
      .markAsRead(me.id, 'news', news.id)
      .subscribe(() => this.onComponentUpdated.emit({}));
    this.trackingService.gtmSend({
      event: 'gaEvent',
      type: 'root_view',
      category: 'Feature announcement',
      action: 'click',
      label: 'Feature announcement: mark as read',
      value: news.id,
    });
  }
}
