import { Component, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'show-errors',
  template: `
    @if (shouldShowErrors()) {
      <ul class="form-error">
        @for (error of listOfErrors(); track error) {
          <li>{{ error }}</li>
        }
      </ul>
    }
  `,
  styleUrls: ['../../styles/_form-error.scss'],
  standalone: true,
})
export class ShowErrorsComponent {
  private static readonly errorMessages = {
    required: (name) => name + ' is required',
    unique: (name) => name + ' must be unique',
    minlength: (_, params) =>
      'The min number of characters is ' + params.requiredLength,
    maxlength: (_, params) =>
      'The max allowed number of characters is ' + params.requiredLength,
    pattern: (name, params) => name + ' pattern is: ' + params.requiredPattern,
    email: () => 'Please verify the email address ',
    queryJson: (_, queryJson) => queryJson.error,
    messageDynamic: (name, params) => params.message(name),
    uniqDeployment: (_, msg) => msg,
    minLengthArray: (_, params) =>
      'The min number of required items is ' + params.requiredLength,
    default: (name) => 'Validation error for ' + name,
    linter: (_, msg) => msg,
  };

  @Input() private control: AbstractControlDirective | AbstractControl;
  @Input() private submited = false;
  @Input() private name: string = null;

  shouldShowErrors(): boolean {
    return (
      this.control?.errors &&
      (this.control?.dirty || this.control?.touched || this.submited)
    );
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors).map((field) =>
      this.getMessage(field, this.control.errors[field]),
    );
  }

  private getMessage(type: string, params: any) {
    if (!(type in ShowErrorsComponent.errorMessages)) {
      return ShowErrorsComponent.errorMessages['default'](type);
    }

    return ShowErrorsComponent.errorMessages[type](this.name, params);
  }
}
