import { Component, inject, Input, OnChanges } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { TrackingService } from '@/app-tracking.service';
import { ModalService } from '@/core/modal/modal.service';
import { FeedbackModalComponent } from '@/user-feedback/feedback-modal/feedback-modal.component';
import { AclDirective } from '@/users/acl.directives';

declare let $;

@Component({
  selector: 'turbine-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, AclDirective],
})
export class SidebarComponent implements OnChanges {
  @Input() isCollapsed = true;
  @Input() configSubmenuCollapsed = true;

  trackingService = inject(TrackingService);
  private modalService = inject(ModalService);

  ngOnChanges() {
    if (this.isCollapsed) {
      $('[data-toggle="tooltip"]').tooltip('enable');
    } else {
      $('[data-toggle="tooltip"]').tooltip('disable');
    }
  }

  collapseHandler(): void {
    this.configSubmenuCollapsed = !this.configSubmenuCollapsed;
  }

  openFeedbackModal(): void {
    const modalRef = this.modalService.showModal({
      class: 'modal-lg',
      initialState: {
        title: 'Feedback',
        child: FeedbackModalComponent,
        mainActionBtnName: 'Send my feedback',
      },
    });
    modalRef.content.onDone.subscribe(() => modalRef.hide());
  }
}
